import Cookies from 'js-cookie';
import {
  wootOn,
  addClass,
  loadCSS,
  removeClass,
  onLocationChangeListener,
} from './DOMHelpers';
import {
    getParamFromQuery
} from 'widget/helpers/urlParamsHelper'
import {
  body,
  widgetHolder,
  createBubbleHolder,
  createBubbleIcon,
  bubbleImg,
  chatBubble,
  closeBubble,
  bubbleHolder,
  createNotificationBubble,
  onClickChatBubble,
  onBubbleClick,
  setBubbleText,
  isSmallScreen,
} from './bubbleHelpers';
import { dispatchWindowEvent } from 'shared/helpers/CustomEventHelper';

const EVENT_NAME = 'hiver:widget-ready';

const { VUE_APP_S3BUCKET_OBJECT_NAME, VUE_APP_S3BUCKET_CDN_URL, VUE_APP_IS_BUDDY_ENV } = process.env;

let S3BUCKET_OBJECT_NAME = VUE_APP_S3BUCKET_OBJECT_NAME;
if (VUE_APP_IS_BUDDY_ENV === 'true') {
    S3BUCKET_OBJECT_NAME = 'widget';
}

const WIDGET_URL = `${VUE_APP_S3BUCKET_CDN_URL}/${S3BUCKET_OBJECT_NAME}/widget.html`;

export const IFrameHelper = {
  getUrl({ baseUrl, websiteToken }) {
    let widgetURL = `${baseUrl}/widget`;
    if (WIDGET_URL) {
      widgetURL = WIDGET_URL;
    }
    return `${widgetURL}?website_token=${websiteToken}`;
  },
  createFrame: ({ baseUrl, websiteToken }) => {
    if (IFrameHelper.getAppFrame()) {
      return;
    }

    loadCSS();
    const iframe = document.createElement('iframe');
    const cwCookie = Cookies.get('cw_conversation');
    let widgetUrl = IFrameHelper.getUrl({ baseUrl, websiteToken });
    if (cwCookie) {
      widgetUrl = `${widgetUrl}&cw_conversation=${cwCookie}`;
    }
    iframe.src = widgetUrl;

    iframe.id = 'chatwoot_live_chat_widget';
    iframe.style.visibility = 'hidden';

    let holderClassName = `woot-widget-holder woot--hide woot-elements--${window.$chatwoot.position} hiver-widget-wrapper`; 
    if (window.$chatwoot.hideMessageBubble) {
      holderClassName += ` woot-widget--without-bubble`;
    }
    addClass(widgetHolder, holderClassName);
    widgetHolder.appendChild(iframe);
    body.appendChild(widgetHolder);
    IFrameHelper.initPostMessageCommunication();
    IFrameHelper.initWindowSizeListener();
    IFrameHelper.preventDefaultScroll();
  },
  getAppFrame: () => document.getElementById('chatwoot_live_chat_widget'),
  getBubbleHolder: () => document.getElementsByClassName('woot--bubble-holder'),
  sendMessage: (key, value) => {
    const element = IFrameHelper.getAppFrame();
    element.contentWindow.postMessage(
      `chatwoot-widget:${JSON.stringify({ event: key, ...value })}`,
      '*'
    );
  },
  initPostMessageCommunication: () => {
    window.onmessage = e => {
      if (
        typeof e.data !== 'string' ||
        e.data.indexOf('chatwoot-widget:') !== 0
      ) {
        return;
      }
      const message = JSON.parse(e.data.replace('chatwoot-widget:', ''));
      if (typeof IFrameHelper.events[message.event] === 'function') {
        IFrameHelper.events[message.event](message);
      }
    };
  },
  initWindowSizeListener: () => {
    wootOn(window, 'resize', () => {
        IFrameHelper.toggleDocumentWidth();
        IFrameHelper.toggleCloseButton();
    });
  },
  preventDefaultScroll: () => {
    widgetHolder.addEventListener('wheel', event => {
      const deltaY = event.deltaY;
      const contentHeight = widgetHolder.scrollHeight;
      const visibleHeight = widgetHolder.offsetHeight;
      const scrollTop = widgetHolder.scrollTop;

      if (
        (scrollTop === 0 && deltaY < 0) ||
        (visibleHeight + scrollTop === contentHeight && deltaY > 0)
      ) {
        event.preventDefault();
      }
    });
  },
  events: {
    loaded: message => {
      Cookies.set('cw_conversation', message.config.authToken, {
        expires: 365,
        sameSite: 'Lax',
      });
      window.$chatwoot.hasLoaded = true;
      IFrameHelper.sendMessage('config-set', {
        locale: window.$chatwoot.locale,
        position: window.$chatwoot.position,
        hideMessageBubble: window.$chatwoot.hideMessageBubble,
        showPopoutButton: window.$chatwoot.showPopoutButton,
        documentWidth: window.innerWidth || document.documentElement.clientWidth || document.body.clientWidth,
        widgetLocationUrl: window.location.href,
      });
      IFrameHelper.onLoad({
        widgetColor: message.config.channelConfig.widgetColor,
      });
      IFrameHelper.toggleCloseButton();

      if (window.$chatwoot.user) {
        IFrameHelper.sendMessage('set-user', window.$chatwoot.user);
      }
      dispatchWindowEvent(EVENT_NAME);
    },

    setBubbleLabel(message) {
      if (window.$chatwoot.hideMessageBubble) {
        return;
      }
      setBubbleText(window.$chatwoot.launcherTitle || message.label);
    },

    toggleBubble: () => {
      onBubbleClick();
    },

    nudgeClicked: () => {            
      IFrameHelper.sendMessage('onNudgeClick');
      adjustWidgetWrapperHeight('click');
    },

    unreadMessageClicked: () => {
      adjustWidgetWrapperHeight('click');
    },

    unreadMessageShown: ({ height, count }) => {
      adjustWidgetWrapperHeight('shown', height, count);
    },

    onBubbleToggle: isOpen => {
      if (!isOpen) {
        IFrameHelper.events.resetUnreadMode();
        IFrameHelper.sendMessage('on-bubble-close');
      } else {
        IFrameHelper.pushEvent('webwidget.triggered');
        IFrameHelper.sendMessage('onBubbleClick');
      }      
    },
    onLocationChange: ({ referrerURL, referrerHost }) => {
      IFrameHelper.sendMessage('change-url', { referrerURL, referrerHost });
    },

    setUnreadMode: message => {
      const { unreadMessageCount } = message;
      const { isOpen } = window.$chatwoot;
      const toggleValue = true;

      if (!isOpen && unreadMessageCount > 0) {
        IFrameHelper.sendMessage('set-unread-view');
        onBubbleClick({ toggleValue });
        const holderEl = document.querySelector('.woot-widget-holder');
        addClass(holderEl, 'has-unread-view');
      }
    },

    setCampaignMode: () => {
      const { isOpen } = window.$chatwoot;
      const toggleValue = true;
      if (!isOpen) {
        onBubbleClick({ toggleValue, campaignMode: true });
        const holderEl = document.querySelector('.woot-widget-holder');
        addClass(holderEl, 'has-unread-view');
      }
    },

    resetUnreadMode: () => {
      IFrameHelper.sendMessage('unset-unread-view');
      IFrameHelper.events.removeUnreadClass();
    },

    removeUnreadClass: () => {
      const holderEl = document.querySelector('.woot-widget-holder');
      removeClass(holderEl, 'has-unread-view');
    },
  },
  pushEvent: eventName => {
    IFrameHelper.sendMessage('push-event', { eventName });
  },

  onLoad: ({ widgetColor }) => {
    IFrameHelper.sendMessage('onChatWidgetLoaded');
    const iframe = IFrameHelper.getAppFrame();
    iframe.style.visibility = '';
    iframe.setAttribute('id', `chatwoot_live_chat_widget`);

    if (IFrameHelper.getBubbleHolder().length) {
      return;
    }
    createBubbleHolder();
    onLocationChangeListener();
    if (!window.$chatwoot.hideMessageBubble) {
      const chatIcon = createBubbleIcon({
        className: 'woot-widget-bubble',
        src: bubbleImg,
        target: chatBubble,
      });

      const closeIcon = closeBubble;
      const closeIconclassName = `woot-elements--${window.$chatwoot.position} woot-widget-bubble woot--close woot--hide`;
      addClass(closeIcon, closeIconclassName);

      chatIcon.style.background = widgetColor;
      closeIcon.style.background = widgetColor;

      bubbleHolder.appendChild(chatIcon);
      bubbleHolder.appendChild(closeIcon);
      bubbleHolder.appendChild(createNotificationBubble());
      onClickChatBubble();
    }

    const referrer = getParamFromQuery('referrer');
    if (referrer) {
        IFrameHelper.sendMessage('change-url', { referrerURL: referrer, referrerHost: referrer });
    }
  },
  toggleCloseButton: () => {
    if (window.matchMedia('(max-width: 668px)').matches) {
      IFrameHelper.sendMessage('toggle-close-button', {
        showClose: true,
      });
    } else {
      IFrameHelper.sendMessage('toggle-close-button', {
        showClose: false,
      });
    }
  },
  toggleDocumentWidth: () => {
    IFrameHelper.sendMessage('inner-document-width', {
        documentWidth: window.innerWidth || document.documentElement.clientWidth || document.body.clientWidth
    });
  }
};

const adjustWidgetWrapperHeight = (action, height, count) => {
  const widgetWrapper = document.querySelector('.hiver-widget-wrapper');
  const browserZoomLevel = Math.round(window.devicePixelRatio * 100);
  if (!widgetWrapper) return;
  let widgetWrapperHeight = '100%';
  switch (action) {
    case 'click':
      if (!isSmallScreen()) {
        widgetWrapperHeight = `calc(${
            browserZoomLevel > 120 ? '93%' : '85%'
          } - 60px - 20px)`;
      } 
      widgetWrapper.style.height = widgetWrapperHeight;

      break;
    case 'shown':
      if (
        widgetWrapper.classList.contains('has-unread-view') &&
        height &&
        count > 0
      ) {
        widgetWrapper.style.height = `${100 + height}px`;
      }
      if (isSmallScreen() && (!height || height < 10)) {
        widgetWrapper.style.height = '200px';
      }
      break;
    default:
      break;
  }
};
